import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { authGuard } from '@mds/angular-core';
import { LayoutComponent } from '@mds/angular-layout-primeng';
import { guidMatcher } from './shared/config/guid.factory';
import { hasRequiredScopesGuard } from './shared/guards/has-required-scopes.guard';
import { OrganizationGuard } from './shared/guards/organization.guard';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'organization-switch',
        loadChildren: () =>
          import('./organization-switch/organization-switch.module').then((m) => m.OrganizationSwitchModule),
        canActivate: [authGuard],
      },
      {
        path: 'invitations',
        loadChildren: () => import('./invitation/invitation.module').then((m) => m.InvitationModule),
        canActivate: [authGuard],
      },
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
        canActivate: [authGuard],
      },
      {
        path: 'errors',
        loadChildren: () => import('./error/error.module').then((m) => m.ErrorModule),
      },
      {
        matcher: guidMatcher,
        canActivate: [authGuard, OrganizationGuard],
        children: [
          {
            path: 'my-organization',
            loadChildren: () => import('./my-organization/my-organization.module').then((m) => m.MyOrganizationModule),
          },
          {
            path: 'organizations',
            loadChildren: () => import('./organization/organization.module').then((m) => m.OrganizationModule),
            canActivate: [hasRequiredScopesGuard(['organizations:read'])],
          },
          {
            path: 'groups',
            loadChildren: () => import('./group/group.module').then((m) => m.GroupModule),
            canActivate: [hasRequiredScopesGuard(['groups:read'])],
          },
          {
            path: 'users',
            loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
            canActivate: [hasRequiredScopesGuard(['users:read'])],
          },
          {
            path: 'roles',
            loadChildren: () => import('./role/role.module').then((m) => m.RoleModule),
            canActivate: [hasRequiredScopesGuard(['roles:read'])],
          },
          {
            path: 'apps',
            loadChildren: () => import('./app/app.module').then((m) => m.AppModule),
            canActivate: [hasRequiredScopesGuard(['apps:read'])],
          },
          {
            path: 'clients',
            loadChildren: () => import('./client/client.module').then((m) => m.ClientModule),
            canActivate: [hasRequiredScopesGuard(['clients:read'])],
          },
          {
            path: 'audits',
            loadChildren: () => import('./audit/audit.module').then((m) => m.AuditModule),
            canActivate: [hasRequiredScopesGuard(['audits:read'])],
          },
        ],
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'errors/404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      preloadingStrategy: PreloadAllModules,
      canceledNavigationResolution: 'computed',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
