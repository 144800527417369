import { Component, HostBinding, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Destroy, LoadingService } from '@mds/angular-core';
import { Observable } from 'rxjs';
import { IDirtyCheckComponent } from '../../models/dirty-check.model';
import { ErrorMapTyped, Formify } from '../../models/form.model';

@Component({
  template: '',
})
export abstract class BaseCreateComponent<TModel> extends Destroy implements IDirtyCheckComponent {
  @HostBinding('class.mds-center-limit-width') private readonly isCentered = true;

  readonly errorMap: ErrorMapTyped<TModel>;
  readonly isLoading$: Observable<boolean>;
  form: FormGroup<Formify<TModel>>;

  get isDirty(): boolean {
    return this.form.touched;
  }

  constructor() {
    super();
    this.isLoading$ = inject(LoadingService).isLoading$;
  }
}
