import { Inject, Injectable } from '@angular/core';
import {
  INavigationItem,
  LayoutStateService,
  MDS_NAVIGATION_ITEMS,
  NavigationService,
} from '@mds/angular-layout-primeng';
import { BehaviorSubject, Observable, combineLatest, map, startWith } from 'rxjs';
import { RequiredScopes, UserScopesModel } from '..';
import { UserScopesService } from './user-scopes.service';

@Injectable()
export class FilteredNavigationService extends NavigationService {
  private readonly internalNavigationItemsSubject = new BehaviorSubject<INavigationItem[]>([]);

  constructor(
    @Inject(MDS_NAVIGATION_ITEMS) navigationItems: INavigationItem[],
    layoutStateService: LayoutStateService,
    userScopesService: UserScopesService
  ) {
    super(navigationItems, layoutStateService);

    combineLatest([super.navigationItems$, userScopesService.getScopes()])
      .pipe(
        map(([items, userScopes]) => this.filterMenuItems(items, userScopes)),
        startWith([])
      )
      .subscribe(this.internalNavigationItemsSubject);
  }

  get navigationItems$(): Observable<INavigationItem[]> {
    return this.internalNavigationItemsSubject.asObservable();
  }

  private filterMenuItems(navigationItems: INavigationItem[], userScopes: UserScopesModel): INavigationItem[] {
    return navigationItems.filter((x) => this.isMenuItemVisible(x, userScopes));
  }

  private isMenuItemVisible(navigationItem: INavigationItem, userScopes: UserScopesModel): boolean {
    if (navigationItem.state === undefined || navigationItem.state[RequiredScopes] === undefined) {
      return true;
    }

    const hasReadScopes = (navigationItem.state[RequiredScopes] as string[]).every((value) => {
      return userScopes.values.includes(value);
    });

    return hasReadScopes;
  }
}
