import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DynamicScopesService {
  private readonly dynamicScopesState = 'dynamic-scopes';
  private sessionPersistedParams: HttpParams;
  private internalParams: HttpParams;

  constructor() {
    const state = localStorage.getItem(this.dynamicScopesState);
    this.sessionPersistedParams = new HttpParams(state ? { fromString: state } : undefined);
    this.internalParams = this.sessionPersistedParams;
  }

  getParams(): HttpParams {
    return this.internalParams;
  }

  set(key: string, value: string | number | string[] | number[], persistBetweenSessions = true): void {
    function addOrReplace(params: HttpParams): HttpParams {
      if (params.has(key)) {
        params = params.delete(key);
      }

      return params.appendAll({ [key]: value });
    }

    this.internalParams = addOrReplace(this.internalParams);
    if (persistBetweenSessions) {
      this.updateBetweenSessionParams(addOrReplace(this.sessionPersistedParams));
    }
  }

  remove(key: string): void {
    this.internalParams = this.internalParams.delete(key);
    if (this.sessionPersistedParams.has(key)) {
      this.updateBetweenSessionParams(this.sessionPersistedParams.delete(key));
    }
  }

  has(key: string): boolean {
    return this.internalParams.has(key);
  }

  get(key: string): string | null {
    return this.internalParams.get(key);
  }

  private updateBetweenSessionParams(params: HttpParams): void {
    this.sessionPersistedParams = params;
    const state = this.sessionPersistedParams.toString();
    localStorage.setItem(this.dynamicScopesState, state);
  }
}
