import { Injectable, inject } from '@angular/core';
import { INavigationItem } from '@mds/angular-layout-primeng/lib/models/navigation.model';
import { Observable, first } from 'rxjs';
import { RequiredScopes } from '../models/required-scopes.model';
import { UserScopesModel } from './api-client';
import { UserScopesService } from './user-scopes.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationItemsDisablerService {
  private userScopesModel$: Observable<UserScopesModel>;

  constructor() {
    this.userScopesModel$ = inject(UserScopesService).getScopes();
  }

  disableInaccessibleTabs(navigationItems: INavigationItem[]): void {
    this.userScopesModel$.pipe(first()).subscribe((userScopesModel) => {
      navigationItems.forEach((x) => (x.disabled = !this.hasRequiredScopes(x, userScopesModel)));
    });
  }

  private hasRequiredScopes(navigationItem: INavigationItem, userScopesModel: UserScopesModel): boolean {
    if (navigationItem.state === undefined || navigationItem.state[RequiredScopes] === undefined) {
      return true;
    }

    const hasRequiredScopes = (navigationItem.state[RequiredScopes] as string[]).every((value) => {
      return userScopesModel.values.includes(value);
    });

    return hasRequiredScopes;
  }
}
