import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TitleStrategy } from '@angular/router';
import {
  API_BASE_URL,
  AppConfigService,
  AuthService,
  CoreModule,
  MDS_API_CONFIG_URL,
  MDS_APP_CONFIG,
  MDS_CONFIG_SERVICE,
  MDS_SUPPORTED_CULTURES,
  MDS_TRANSLATE_LOADERS,
  MDS_TRANSLATE_PROVIDER,
} from '@mds/angular-core';
import {
  LayoutModule,
  MDS_NAVIGATION_ITEMS,
  MDS_PROFILE_ITEMS,
  MDS_TOOLBAR_COMPONENTS,
  NavigationService,
} from '@mds/angular-layout-primeng';
import { AuthenticationModule } from '@mds/authentication';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CurrentOrganizationComponent } from './organization-switch/components/current-organization/current-organization.component';
import { ConfigurationModel } from './shared';
import { getApiBaseUrl, getApiConfigUrl } from './shared/config/context.config';
import { supportedCultures } from './shared/config/culture.config';
import { navigationGroupsFactory } from './shared/config/navigation.factory';
import { createProfileItems } from './shared/config/profile-items.factory';
import { WebpackTranslateLoader } from './shared/config/translate-loader';
import { ForbiddenInterceptor } from './shared/interceptors/forbidden.interceptor';
import { MfaRequiredInterceptor } from './shared/interceptors/mfa-required.interceptor';
import { FilteredNavigationService } from './shared/services/filtered-navigation.service';
import { OrganizationSelectionService } from './shared/services/organization-selection.service';
import { TranslatedTitleStrategy } from './shared/services/translated-title-strategy.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    CoreModule,
    LayoutModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: MDS_TRANSLATE_PROVIDER,
    }),
    AppRoutingModule,
    AuthenticationModule.forRoot<ConfigurationModel>((x) => x.authConfig),
  ],
  providers: [
    {
      provide: MDS_PROFILE_ITEMS,
      useFactory: createProfileItems,
      deps: [AuthService, OrganizationSelectionService],
      multi: true,
    },
    { provide: MDS_SUPPORTED_CULTURES, useValue: supportedCultures },
    { provide: MDS_API_CONFIG_URL, useFactory: getApiConfigUrl },
    { provide: MDS_CONFIG_SERVICE, useClass: AppConfigService },
    { provide: API_BASE_URL, useFactory: getApiBaseUrl },
    { provide: MDS_TRANSLATE_LOADERS, useClass: WebpackTranslateLoader, multi: true },
    {
      provide: MDS_NAVIGATION_ITEMS,
      useFactory: navigationGroupsFactory,
      deps: [OrganizationSelectionService, MDS_APP_CONFIG],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ForbiddenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MfaRequiredInterceptor,
      multi: true,
    },
    {
      provide: MDS_TOOLBAR_COMPONENTS,
      useValue: CurrentOrganizationComponent,
      multi: true,
    },
    { provide: NavigationService, useClass: FilteredNavigationService },
    {
      provide: TitleStrategy,
      useClass: TranslatedTitleStrategy,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
