import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { AuthService } from '@mds/angular-core';
import { MessageService } from '@mds/angular-layout-primeng';
import { DynamicScopesService } from '@mds/authentication';
import { Observable, ReplaySubject, catchError, filter, map, of, switchMap } from 'rxjs';
import { ClaimTypes } from '../models/claim-types.model';
import { MeClient, UserScopeParameterValueModel } from './api-client';

@Injectable({
  providedIn: 'root',
})
export class OrganizationSelectionService {
  private readonly organizationScope = 'planzer.id.orgid';
  private readonly selectionClearedErrorState = 'selection-cleared-error';
  private readonly organizationSubject = new ReplaySubject<UserScopeParameterValueModel | undefined>();

  constructor(
    @Inject(DynamicScopesService) private readonly dynamicScopesService: DynamicScopesService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly meClient: MeClient,
    private readonly messageService: MessageService
  ) {
    this.getOrganization().subscribe((organization) => {
      if (this.dynamicScopesService.has(this.organizationScope) && organization == undefined) {
        // the users organization could not be loaded despite it getting selected
        this.setSelectionClearedError();
        this.clearOrganizationSelection();
      }
    });

    this.authService.isAuthenticated$
      .pipe(
        filter((isAuthenticated) => isAuthenticated),
        switchMap(() => this.meClient.getCurrentScopeParameterValues(ClaimTypes.Organization, true)),
        map((values) => (values.length === 1 ? values[0] : undefined)),
        catchError(() => {
          return of(undefined);
        })
      )
      .subscribe(this.organizationSubject);

    if (this.hasSelectionClearedError()) {
      messageService.add({
        severity: 'warn',
        textResource: _('Iam.OrganizationSwitch.Notification.UnauthorizedAndDeselected.Warn'),
      });
      this.unsetSelectionClearedError();
    }
  }

  getOrganization(): Observable<UserScopeParameterValueModel | undefined> {
    return this.organizationSubject.asObservable();
  }

  getOrganizations(): Observable<UserScopeParameterValueModel[]> {
    return this.meClient.getScopeParameterValues(ClaimTypes.Organization);
  }

  openOrganizationSelection(): void {
    this.router.navigate(['/organization-switch']);
  }

  switchOrganization(organizationId: string, targetUrl?: string): void {
    this.canSwitchToOrganization(organizationId).subscribe((canSwitch) => {
      if (canSwitch) {
        this.dynamicScopesService.set(this.organizationScope, organizationId, true);
        this.authService.login(targetUrl);
      } else {
        this.openHome();
        this.messageService.add({
          severity: 'warn',
          textResource: _('Iam.OrganizationSwitch.Notification.UnauthorizedAndCancelled.Warn'),
        });
      }
    });
  }

  clearOrganizationSelection(): void {
    this.dynamicScopesService.remove(this.organizationScope);
    this.authService.login();
  }

  private canSwitchToOrganization(organizationId: string): Observable<boolean> {
    return this.getOrganizations().pipe(map((orgs) => orgs.find((x) => x.value === organizationId) !== undefined));
  }

  private openHome(): void {
    this.router.navigate(['/home']);
  }

  private setSelectionClearedError(): void {
    localStorage.setItem(this.selectionClearedErrorState, '1');
  }

  private unsetSelectionClearedError(): void {
    localStorage.removeItem(this.selectionClearedErrorState);
  }

  private hasSelectionClearedError(): boolean {
    return localStorage.getItem(this.selectionClearedErrorState) != null;
  }
}
