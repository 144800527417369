import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, catchError, throwError } from 'rxjs';

@Injectable()
export class MfaRequiredInterceptor implements HttpInterceptor {
  constructor(private readonly router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          error.status === 403 &&
          error.error.detail &&
          error.error.detail == 'MfaRequirementNotMet'
        ) {
          this.router.navigate(['errors', 'mfa-redirect'], { relativeTo: null });
          return EMPTY;
        }
        return throwError(() => error);
      })
    );
  }
}
