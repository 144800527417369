import { Injectable } from '@angular/core';
import { AuthService } from '@mds/angular-core';
import { EMPTY, Observable, ReplaySubject, catchError, combineLatest, filter, switchMap } from 'rxjs';
import { MeClient, UserScopesModel } from './api-client';

@Injectable({
  providedIn: 'root',
})
export class UserScopesService {
  private readonly userScopesSubject = new ReplaySubject<UserScopesModel>();

  constructor(private readonly authService: AuthService, private readonly meClient: MeClient) {
    combineLatest([this.authService.isLoaded$, this.authService.isAuthenticated$])
      .pipe(
        filter(([isLoaded, isAuthenticated]) => isLoaded && isAuthenticated),
        switchMap(() => this.meClient.getScopes(true)),
        catchError(() => {
          return EMPTY;
        })
      )
      .subscribe(this.userScopesSubject);
  }

  getScopes(): Observable<UserScopesModel> {
    return this.userScopesSubject.asObservable();
  }
}
