import { Router } from '@angular/router';
import { INavigationItem } from '@mds/angular-layout-primeng';
import { map, Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

export function createProfileItems(authService: AuthenticationService, router: Router): Observable<INavigationItem[]> {
  return authService.isAuthenticated$.pipe(
    map((isAuthenticated) => {
      const menuItem = isAuthenticated
        ? [
            {
              labelResource: 'Authentication.Profile.ViewAccount',
              icon: 'pi pi-user',
              command: () => authService.viewAccount(),
              id: 'viewaccount',
            },
            {
              labelResource: 'Authentication.Profile.Logout',
              icon: 'pi pi-sign-out',
              command: () => authService.logout(),
              id: 'logout',
            },
          ]
        : [
            {
              labelResource: 'Authentication.Profile.Login',
              icon: 'pi pi-sign-in',
              command: () => authService.login(router.url),
              id: 'login',
            },
          ];

      return menuItem;
    })
  );
}
