import { UrlMatcher, UrlSegment } from '@angular/router';

export const guidMatcher: UrlMatcher = (segments: UrlSegment[]) => {
  if (segments.length === 0) {
    return null;
  }

  if (segments[0].path.length !== 36) {
    return null;
  }

  if (!segments[0].path.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g)) {
    return null;
  }

  return { consumed: [segments[0]], posParams: { orgId: segments[0] } };
};
