import { Component, OnInit, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Destroy } from '@mds/angular-core';
import { isEqual } from 'lodash-es';
import { LazyLoadEvent } from 'primeng/api';
import { BehaviorSubject, Observable, Subject, combineLatest, of, timer } from 'rxjs';
import { debounce, map, startWith, switchMap, tap } from 'rxjs/operators';
import { Formify } from '../../models/form.model';
import { PaginatedListModel } from '../../models/paginated-list.model';

@Component({
  template: '',
})
export abstract class BasePagedListComponent<TModel, TSearchModel> extends Destroy implements OnInit {
  private readonly isLoadingSubject = new BehaviorSubject<boolean>(false);
  private readonly totalRecordsSubject = new BehaviorSubject<number>(10000);
  private readonly reloadSubject = new BehaviorSubject('initial');
  private readonly dataSubject = new BehaviorSubject<TModel[]>([]);
  private readonly lazyLoadSubject = new Subject<LazyLoadEvent>();

  private lastFilter: TSearchModel;
  private isOnInit = true;

  readonly isLoading$ = this.isLoadingSubject.asObservable();
  readonly totalRecords$ = this.totalRecordsSubject.asObservable();
  readonly data$: Observable<TModel[]>;
  readonly hasWriteScopes$: Observable<boolean>;
  readonly hasDeleteScopes$: Observable<boolean>;
  readonly hasAuditScopes$: Observable<boolean>;

  searchForm: FormGroup<Formify<TSearchModel>>;

  constructor() {
    super();

    const activatedRoute = inject(ActivatedRoute);

    this.data$ = this.dataSubject.asObservable();
    this.hasWriteScopes$ = activatedRoute.data.pipe(map((x) => x.hasWriteScopes as boolean));
    this.hasDeleteScopes$ = activatedRoute.data.pipe(map((x) => x.hasDeleteScopes as boolean));
    this.hasAuditScopes$ = activatedRoute.data.pipe(map((x) => x.hasAuditScopes as boolean));
  }

  ngOnInit(): void {
    combineLatest([this.lazyLoadSubject, this.searchForm.valueChanges.pipe(startWith(undefined)), this.reloadSubject])
      .pipe(
        tap(() => this.dataSubject.next([])),
        debounce(() => (this.isOnInit ? timer(0) : timer(300))),
        tap(() => this.isLoadingSubject.next(true)),
        tap(() => (this.isOnInit = false)),
        switchMap(([event, value]) => this.internalLoadData(event, value as TSearchModel)),
        tap(() => this.isLoadingSubject.next(false))
      )
      .subscribe(this.dataSubject);
  }

  reload(): void {
    this.reloadSubject.next('reload');
  }

  lazyLoad(event: LazyLoadEvent): void {
    this.lazyLoadSubject.next(event);
  }

  protected loadData(_: LazyLoadEvent): Observable<PaginatedListModel<TModel>> {
    return of({
      items: [],
      totalCount: 0,
    } as PaginatedListModel<TModel>);
  }

  private internalLoadData(event: LazyLoadEvent, filter: TSearchModel): Observable<TModel[]> {
    return this.loadData(event).pipe(
      tap((x) => {
        if (!isEqual(this.lastFilter, filter)) {
          this.lastFilter = filter;
        }
        this.totalRecordsSubject.next(x.totalCount);
      }),
      map((x) => x.items)
    );
  }
}
