import { ChangeDetectorRef, Component, HostBinding, OnInit, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Destroy, EqualityService, LoadingService } from '@mds/angular-core';
import { Observable, ReplaySubject, map, takeUntil } from 'rxjs';
import { IDirtyCheckComponent } from '../../models/dirty-check.model';
import { ErrorMapTyped, Formify } from '../../models/form.model';

@Component({
  template: '',
})
export abstract class BaseEditComponent<TEntity extends TModel, TModel>
  extends Destroy
  implements OnInit, IDirtyCheckComponent
{
  @HostBinding('class.mds-center-limit-width') private readonly isCentered = true;
  private initialValue: TModel;

  protected readonly entitySubject = new ReplaySubject<TEntity>();
  protected readonly changeDetectorRef: ChangeDetectorRef;
  protected readonly equalityService: EqualityService;
  protected readonly activatedRoute: ActivatedRoute;
  protected loadEntityFromParentRoute = false;

  readonly errorMap: ErrorMapTyped<TModel>;
  readonly isLoading$: Observable<boolean>;
  readonly hasWriteScopes$: Observable<boolean>;
  readonly hasAuditScopes$: Observable<boolean>;
  form: FormGroup<Formify<TModel>>;

  get isDirty(): boolean {
    return !this.equalityService.isEqual(this.initialValue, this.form.getRawValue());
  }

  constructor() {
    super();
    this.isLoading$ = inject(LoadingService).isLoading$;
    this.equalityService = inject(EqualityService);
    this.changeDetectorRef = inject(ChangeDetectorRef);
    this.activatedRoute = inject(ActivatedRoute);
    this.hasWriteScopes$ = this.activatedRoute.data.pipe(map((x) => x.hasWriteScopes as boolean));
    this.hasAuditScopes$ = this.activatedRoute.data.pipe(map((x) => x.hasAuditScopes as boolean));
  }

  ngOnInit(): void {
    const activatedRoute = this.loadEntityFromParentRoute ? this.activatedRoute.parent : this.activatedRoute;
    activatedRoute?.data.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      const entity = data.entity as TEntity;
      this.entitySubject.next(entity);
      this.form.patchValue(entity as any);
      this.updateInitialValue();
    });

    this.hasWriteScopes$.pipe(takeUntil(this.destroy$)).subscribe((x) => this.setFormEnabled(x));
  }

  protected updateInitialValue(): void {
    this.initialValue = this.form.getRawValue() as TModel;
    this.changeDetectorRef.markForCheck();
  }

  protected setFormEnabled(isEnabled: boolean): void {
    if (isEnabled) {
      this.form.enable();
    } else {
      this.form.disable();
    }
  }
}
