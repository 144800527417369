import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable, map } from 'rxjs';
import { OrganizationSelectionService } from '../../../shared';

@Component({
  selector: 'iam-current-organization',
  templateUrl: './current-organization.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentOrganizationComponent {
  readonly organizationName$: Observable<string | undefined>;
  readonly hasOrganization$: Observable<boolean>;

  constructor(organizationSelectionService: OrganizationSelectionService) {
    this.hasOrganization$ = organizationSelectionService.getOrganization().pipe(map((x) => x !== undefined));
    this.organizationName$ = organizationSelectionService.getOrganization().pipe(map((x) => x?.description));
  }
}
