import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs';
import { UserScopesService } from '../services/user-scopes.service';

export const hasRequiredScopesGuard: (requiredScopes: string[]) => CanActivateFn = (requiredScopes) => () => {
  const router = inject(Router);
  return inject(UserScopesService)
    .getScopes()
    .pipe(
      map((actualScopes) => {
        const hasRequiredScopes =
          requiredScopes && requiredScopes.every((value) => actualScopes.values.includes(value));

        return hasRequiredScopes ? true : router.parseUrl('errors/403');
      })
    );
};
