import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { API_BASE_URL, AuthService, IConfiguration, MDS_TRANSLATE_LOADERS } from '@mds/angular-core';
import { MDS_PROFILE_ITEMS } from '@mds/angular-layout-primeng';
import { OAuthModule, OAuthModuleConfig } from 'angular-oauth2-oidc';
import { createModuleConfig } from './config/module-config.factory';
import { createProfileItems } from './config/profile-items.factory';
import { TranslateAuthenticationLoader } from './i18n/translate-authentication-loader';
import { AuthConfigModel, MDS_AUTH_CONFIG_MAP, MDS_AUTH_DEBUG } from './models/auth-config.model';
import { AuthenticationService } from './services/authentication.service';

@NgModule({
  imports: [CommonModule, OAuthModule],
})
export class AuthenticationModule {
  static forRoot<TConfiguration extends IConfiguration>(
    authConfigMap: (config: TConfiguration) => AuthConfigModel,
    options?: {
      showDebugInformation?: boolean;
    }
  ): ModuleWithProviders<AuthenticationModule> {
    return {
      ngModule: AuthenticationModule,
      providers: [
        ...(OAuthModule.forRoot().providers ?? []),
        AuthenticationService,
        { provide: AuthService, useExisting: AuthenticationService },
        { provide: MDS_AUTH_DEBUG, useValue: options?.showDebugInformation ?? false },
        { provide: MDS_AUTH_CONFIG_MAP, useValue: authConfigMap },
        {
          provide: OAuthModuleConfig,
          useFactory: createModuleConfig,
          deps: [API_BASE_URL],
        },
        {
          provide: MDS_PROFILE_ITEMS,
          useFactory: createProfileItems,
          deps: [AuthService, Router],
          multi: true,
        },
        { provide: MDS_TRANSLATE_LOADERS, useClass: TranslateAuthenticationLoader, multi: true },
      ],
    };
  }
}
