import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { INavigationItem } from '@mds/angular-layout-primeng';
import { Observable, map } from 'rxjs';
import { RequiredScopes } from '../models/required-scopes.model';
import { UserScopeParameterValueModel } from '../services/api-client';
import { OrganizationSelectionService } from '../services/organization-selection.service';

export function navigationGroupsFactory(
  organizationSelectionService: OrganizationSelectionService
): Observable<INavigationItem[]> {
  return organizationSelectionService.getOrganization().pipe(
    map((model) => [
      {
        icon: 'mds mds-home',
        labelResource: _('Iam.Navigation.Home.Title'),
        routerLink: 'home',
      },
      {
        labelResource: _('Iam.Navigation.MyOrganization.Title'),
        routerLink: inOrganization('my-organization', model),
      },
      {
        icon: 'mds mds-organization',
        labelResource: _('Iam.Navigation.Organization.Title'),
        routerLink: inOrganization('organizations', model),
        state: {
          [RequiredScopes]: ['organizations:read'],
        },
      },
      {
        icon: 'mds mds-group',
        labelResource: _('Iam.Navigation.Groups.Title'),
        routerLink: inOrganization('groups', model),
        state: {
          [RequiredScopes]: ['groups:read'],
        },
      },
      {
        icon: 'mds mds-user',
        labelResource: _('Iam.Navigation.Users.Title'),
        routerLink: inOrganization('users', model),
        state: {
          [RequiredScopes]: ['users:read'],
        },
      },
      {
        icon: 'mds mds-role',
        labelResource: _('Iam.Navigation.Roles.Title'),
        routerLink: inOrganization('roles', model),
        state: {
          [RequiredScopes]: ['roles:read'],
        },
      },
      {
        icon: 'mds mds-application',
        labelResource: _('Iam.Navigation.Apps.Title'),
        routerLink: inOrganization('apps', model),
        state: {
          [RequiredScopes]: ['apps:read'],
        },
      },
      {
        icon: 'mds mds-client',
        labelResource: _('Iam.Navigation.Clients.Title'),
        routerLink: inOrganization('clients', model),
        state: {
          [RequiredScopes]: ['clients:read'],
        },
      },
      {
        icon: 'pi pi-eye',
        labelResource: _('Iam.Navigation.Audits.Title'),
        routerLink: inOrganization('audits', model),
        state: {
          [RequiredScopes]: ['audits:read'],
        },
      },
    ])
  );
}

function inOrganization(routerLink: string, organization: UserScopeParameterValueModel | undefined): string {
  return `${organization?.value}/${routerLink}`;
}
