import { AuthService } from '@mds/angular-core';
import { INavigationItem } from '@mds/angular-layout-primeng';
import { map, Observable } from 'rxjs';
import { OrganizationSelectionService } from '../services/organization-selection.service';

export function createProfileItems(
  authService: AuthService,
  organizationSelectionService: OrganizationSelectionService
): Observable<INavigationItem[]> {
  return authService.isAuthenticated$.pipe(
    map((isAuthenticated) => {
      const menuItem = isAuthenticated
        ? [
            {
              labelResource: 'Iam.OrganizationSwitch.Title',
              icon: 'mds mds-organization',
              command: () => organizationSelectionService.openOrganizationSelection(),
              id: 'switch-organization',
            },
          ]
        : [];

      return menuItem;
    })
  );
}
