import { InjectionToken } from '@angular/core';
import { IConfiguration } from '@microsoft/applicationinsights-web';

export const MDS_AUTH_CONFIG_MAP = new InjectionToken<(config: IConfiguration) => AuthConfigModel>(
  'MDS_AUTH_CONFIG_MAP'
);
export const MDS_AUTH_DEBUG = new InjectionToken<boolean>('MDS_AUTH_DEBUG');

export interface AuthConfigModel {
  redirectUri: string;
  myAccountUri: string;
  clientId: string;
  issuer: string;
  responseType: string;
  scope: string;
  resource: string;
  acrValues: string;
}
