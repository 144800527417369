import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, filter, switchMap } from 'rxjs';

@Injectable()
export class TranslatedTitleStrategy extends TitleStrategy {
  private readonly titleResource = new Subject<string>();

  constructor(private readonly title: Title, private readonly translateService: TranslateService) {
    super();
    this.titleResource
      .pipe(
        switchMap((x) => this.translateService.stream(x)),
        filter((x) => !!x)
      )
      .subscribe((x) => this.title.setTitle(x));
  }

  override updateTitle(routerStateSnapshot: RouterStateSnapshot) {
    const title = this.buildTitle(routerStateSnapshot);
    if (title !== undefined) {
      this.titleResource.next(title);
    }
  }
}
