import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { MessageService } from '@mds/angular-layout-primeng';
import { Observable, map } from 'rxjs';
import { OrganizationSelectionService } from '../services/organization-selection.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationGuard implements CanActivate {
  constructor(
    private readonly organizationSelectionService: OrganizationSelectionService,
    private readonly router: Router,
    private readonly messageService: MessageService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const orgId = route.paramMap.get('orgId');

    return this.organizationSelectionService.getOrganization().pipe(
      map((organization) => {
        if (organization != undefined) {
          if (orgId != null && organization.value !== orgId) {
            this.organizationSelectionService.switchOrganization(orgId, state.url);
          }

          return true;
        }

        this.messageService.add({
          severity: 'info',
          textResource: _('Iam.OrganizationSwitch.Notification.NoneActive.Info'),
        });
        this.organizationSelectionService.openOrganizationSelection();
        return false;
      })
    );
  }
}
