import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { de } from './de';
import { en } from './en';
import { fr } from './fr';
import { it } from './it';

export class TranslateAuthenticationLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    let translations = {};
    switch (lang) {
      case 'de':
        translations = de;
        break;
      case 'fr':
        translations = fr;
        break;
      case 'it':
        translations = it;
        break;
      case 'en':
      default:
        translations = en;
    }

    return of(translations);
  }
}
